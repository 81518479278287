import React from 'react';
import { Link } from 'react-router-dom';

import 'stylesheets/mobile_menu_drawer.scss';

const MobileMenuDrawer = () => {
  const handleClose = () => {
    document.getElementById('menu-drawer').style.maxWidth = '0px';
  };

  return (
    <div className='mobile-menu-drawer-wrapper' id="menu-drawer">
      <div className='mobile-menu-drawer-header-row'>
        <div>MENU</div>
        <img alt="close" src="/icon/menu-close-white.png" onClick={handleClose} />
      </div>
      <div className='mobile-menu-drawer-content-col'>
        <Link to="/brand">
          <div className='mobile-menu-item'>Brand</div>
        </Link>
        <Link to="/business">
          <div className='mobile-menu-item'>Business</div>
        </Link>
        <Link to="/recruit">
          <div className='mobile-menu-item'>Recruit</div>
        </Link>
        <Link to="/blog">
          <div className='mobile-menu-item'>Blog</div>
        </Link>
        <Link to="/contact">
          <div className='mobile-menu-item'>Contact</div>
        </Link>
      </div>
    </div>
  );
};

export default MobileMenuDrawer;
