const getQuery = (queryString) => {
  if (queryString === '') return {};

  const queries = queryString.split('?')[1].split('&');

  const obj = {};

  queries.forEach((query) => {
    const queryValue = query.split('=');
    obj[queryValue[0]] = queryValue[1];
  });

  return obj;
};

export default getQuery;
