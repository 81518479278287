import React from 'react';

import Container from 'components/container';

import 'stylesheets/brand.scss';

const Brand = () => {
  return (
    <Container paddingTop={0} backgroundColor='#111'>
      <div className='brand-banner-wrapper'>
        <div className='brand-banner-container' />
        <div className='brand-banner-content-section'>
          <div className='brand-banner-page-title'>Brand</div>
          <div className='brand-banner-title'>Draw Inspiring Circles</div>
          <div className='brand-banner-content'>디에이티는 사람들이 서로 Inspiration을 주고 받는 것을 Inspiring Circle로 정의하고,<br />이러한 선순환을 지속적으로 만들고자 합니다.</div>
        </div>
      </div>
      <div className='brand-mission-wrapper'>
        <div className='brand-mission-line' />
        <div className='brand-mission-col'>
          <div className='brand-mission-label'>Mission</div>
          <div className='brand-mission-statement'>To inspire world,<br /> regardless of time and place.</div>
          <div className='brand-mission-intro'>
            지구촌의 더 많은 사람들이 다양한 형태의 Inspiration을<br />시간과 장소에 제약없이 주고 받을 수 있는 환경을 만들고자 합니다.
          </div>
        </div>
        <div className='brand-mission-container'>
          <img src='/img/brand-mission.jpg' alt='brand-mission' className='brand-mission-img' />
        </div>
      </div>
      <div className='brand-vision-wrapper'>
        <div className='brand-vision-line' />
        <div className='brand-vision-container'>
          <img src='/img/brand-vision.jpg' alt='brand-vision' className='brand-vision-img' />
        </div>
        <div className='brand-vision-col'>
          <div className='brand-vision-label'>Vision</div>
          <div className='brand-vision-statement'>Be a inspirer,<br />and make someone a inspirer<br />to inspire world.</div>
          <div className='brand-vision-intro'>
            우리는 더 많은 사람들이 긍적적인 Inspiration을 받길 원하고,<br />이를 위한 가장 좋은 방법은 Inspiring Circle을 만드는 것이라고 생각합니다.
          </div>
        </div>
      </div>
      <div className='brand-corevalue-wrapper'>
        <div className='brand-corevalue-container'>
          <div className='brand-corevalue-label'>Core Value</div>
          <div className='brand-corevalue-row'>
            <div className='brand-corevalue-block'>
              <div className='brand-corevalue-title'>Expertise</div>
              <div className='brand-corevalue-intro'>디에이티는 다양한 IT분야의 전문가들로 구성되어있습니다. 비즈니스 현장에서의 Insight들과 디에이티의 IT 전문지식이 융합하여 새로운 Business Value를 창출합니다.</div>
            </div>
            <div className='brand-corevalue-block'>
              <div className='brand-corevalue-title'>Flexibility</div>
              <div className='brand-corevalue-intro'>고객의 Environments, Business Strategies, Pain points에 따라 가장 최적화된 솔루션을 설계하고, 개발합니다.</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Brand;
