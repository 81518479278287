import React from 'react';

import Container from 'components/container';

import 'stylesheets/contact.scss';

const Contact = () => {
  return (
    <Container paddingTop={0}>
      <div className="contact-info-wrapper">
        <div className="contact-info-area">
          <div className="contact-info-title">최선을 다해 답변드리겠습니다</div>
          <div className="contact-info-script">문의하기를 작성하시거나 아래 기재된 이메일로 문의를 남겨주시면 신속하게 답변해 드리겠습니다.</div>
          <div className="contact-info-set">
            <div className="contact-info-company">주식회사 디에이티</div>
            <div className="contact-info-email">
              <div>이메일</div>
              <div>contact@d-eighty.com</div>
            </div>
            <div className="contact-info-address">
              <div>주소</div>
              <div>대구광역시 북구 동북로 223 3층</div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-form-wrapper">
        <div className="contact-form-area">
          <div className="contact-form-title">문의하기</div>
          <div className="contact-form-input-org">
            <div className="contact-form-input-label">소속</div>
            <div className="contact-form-radio-set">
              <div class="contact-form-radio-selectarea">
                <input type="radio" name="org" id="company"/>
                <label for="company"><div className="fake-radio"><div></div></div>일반 기업</label>
              </div>
              <div class="contact-form-radio-selectarea">
                <input type="radio" name="org" id="lab"/>
                <label for="lab"><div className="fake-radio"><div></div></div>대학 연구실</label>
              </div>
              <div class="contact-form-radio-selectarea">
                <input type="radio" name="org" id="government"/>
                <label for="government"><div className="fake-radio"><div></div></div>공공기관·정부부처·지자체</label>
              </div>
            </div>
          </div>
          <div className="contact-form-input-company">
            <div className="contact-form-input-label">회사명</div>
            <input type="text" placeholder="ex) 주식회사디에이티"/>
          </div>
          <div className="contact-form-input-name">
            <div className="contact-form-input-label">이름</div>
            <input type="text" placeholder="홍길동"/>
          </div>
          <div className="contact-form-input-tel">
            <div className="contact-form-input-label">전화번호</div>
            <input type="tel" maxlength="11" placeholder="01012341234"/>
          </div>
          <div className="contact-form-input-email">
            <div className="contact-form-input-label">이메일</div>
            <input type="text" placeholder="name@email.com"/>
          </div>
          <div className="contact-form-input-text">
            <div className="contact-form-input-label">문의내용</div>
            <textarea type="text" placeholder="ex) 앱 서비스 개발 관련 문의 드립니다."/>
          </div>
          <div className="contact-form-upload">
            <div className="contact-form-input-label">파일첨부</div>
            <button><img src="/icon/upload.png"/><span>파일 업로드</span></button>
          </div>
          <div className="contact-form-submit-wrapper">
            <button className="contact-form-submit">제출하기</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
