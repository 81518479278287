import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';

import Container from 'components/container';

import {CLIENT_TYPE, clientName, clientDetail, clientImage} from 'configs/client';
import getQuery from 'utils/get_guery';

import 'stylesheets/business.scss';

const Business = ({history}) => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const [tab, setTab] = useState(CLIENT_TYPE.COM);

  useEffect(() => {
    const query = getQuery(search);
    if (query['tab'] === CLIENT_TYPE.LAB) setTab(CLIENT_TYPE.LAB);
    else if (query['tab'] === CLIENT_TYPE.GOV) setTab(CLIENT_TYPE.GOV);
    else setTab(CLIENT_TYPE.COM);
  }, [search]);

  const moveConsultingTab = (type) => () => {
    navigate(`?tab=${type}`, {replace: true});
  };

  const getConsultingTabStyle = (type) => {
    let classes = 'business-consulting-tab-menu';

    if (type === tab) classes += ' consulting-tab-active';

    return classes;
  };

  const getTabTitle = () => clientName[tab];

  const getTabContent = () => clientDetail[tab];

  const getTabImage = () => clientImage[tab];

  return (
    <Container paddingTop={0}>
      <div className='business-banner-wrapper'></div>
      <div className='business-overview-wrapper'>
        <div className='business-label'>Business</div>
        <div className='business-title'>Digital Transformation</div>
        <div className='business-content'>다양한 산업분야의 전문가와의 협업을 언제나 환영합니다.<br />IT 기술을 도입하여, 새로운 기회를 찾으세요.</div>
      </div>
      <div className='business-section0-wrapper'>
        <div className='business-section0-contentarea'>
          <div className='business-title-wrapper'>
            <div className='business-title-number'>00</div>
            <div className='business-title'>Consulting</div>
          </div>
          <div className='business-content'>IT 기술기반의 접근방식으로 문제를 정의하고, 해결방법을 제안합니다.</div>
          <div className='business-consulting-tab-wrapper'>
            <div className='business-consulting-tab-menu-wrapper'>
              <div className={getConsultingTabStyle(CLIENT_TYPE.COM)} onClick={moveConsultingTab(CLIENT_TYPE.COM)}><img alt="company" src='/icon/consulting-tab-1.png' />일반 기업</div>
              <div className={getConsultingTabStyle(CLIENT_TYPE.LAB)} onClick={moveConsultingTab(CLIENT_TYPE.LAB)}><img alt="lab" src='/icon/consulting-tab-2.png' />대학 연구실</div>
              <div className={getConsultingTabStyle(CLIENT_TYPE.GOV)} onClick={moveConsultingTab(CLIENT_TYPE.GOV)}><img alt="government" src='/icon/consulting-tab-3.png' />공공기관·정부부처·지자체</div>
            </div>
            <div className='business-consulting-tab-content-wrapper'>
              <div className='business-consulting-img' style={{backgroundImage: `url(${getTabImage()})`}}></div>
              <div className='business-consulting-text-wrapper'>
                <div className='business-consulting-title'>{getTabTitle()}</div>
                <div className='business-consulting-content'>{getTabContent()}</div>
                <Link to="/contact">
                  <div className='business-consulting-btn'>문의하기<img alt="arrow-right" src="/icon/arrow-right-black-16.png" /></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='business-section1-wrapper'>
        <div className='business-section1-contentarea'>
          <div className='business-title-wrapper'>
            <div className='business-title-number'>01</div>
            <div className='business-title'>Development</div>
          </div>
          <div className='business-content'>문제를 해결하기 위한 방법으로, IT 솔루션을 개발합니다.</div>
          <div className='business-section1-container'>
            <div className='business-section1-card'>
              <div className='business-section1-card-text'>
                <div>Web</div>
                <div>더 나은 End User 사용자 경험을 위해, React & Redux 기반 Client-side Application을 개발합니다.</div>
              </div>
              <div className='business-section1-card-img'>
                <div>
                  <img alt='react js' src='/icon/reactjs.png' />
                  <p>React JS</p>
                </div>
                <div>
                  <img alt='redux' src='/icon/redux.png' />
                  <p>Redux</p>
                </div>
                <div>
                  <img alt='next js' src='/icon/nextjs.png' />
                  <p>Next JS</p>
                </div>
              </div>
            </div>
            <div className='business-section1-card'>
              <div className='business-section1-card-text'>
                <div>Mobile App (Android/iOS)</div>
                <div>새롭게 정의 된 문제와, 고객의 상황에 따라, Native, Cross Platform을 제안하고, 개발합니다.</div>
              </div>
              <div className='business-section1-card-img'>
                <div>
                  <img alt='flutter' src='/icon/flutter.png' />
                  <p>Flutter</p>
                </div>
                <div>
                  <img alt='get x' src='/icon/getx.png' />
                  <p>Get X</p>
                </div>
                <div>
                  <img alt='kotlin' src='/icon/kotlin.png' />
                  <p>Kotlin</p>
                </div>
                <div>
                  <img alt='swift' src='/icon/swift.png' />
                  <p>Swift</p>
                </div>
              </div>
            </div>
            <div className='business-section1-card'>
              <div className='business-section1-card-text'>
                <div>Desktop App (Window/MacOS/Linux)</div>
                <div></div>
              </div>
              <div className='business-section1-card-img'>
                <div>
                  <img alt='flutter' src='/icon/flutter.png' />
                  <p>Flutter</p>
                </div>
                <div>
                  <img alt='get x' src='/icon/getx.png' />
                  <p>Get X</p>
                </div>
                <div>
                  <img alt='electron' src='/icon/electron.png' />
                  <p>Electron</p>
                </div>
              </div>
            </div>
            <div className='business-section1-card'>
              <div className='business-section1-card-text'>
                <div>Server</div>
                <div>Capacity와 Scalability 고려하여, 가장 적합한 클라우드 시스템을 설계하고, 구축합니다.</div>
              </div>
              <div className='business-section1-card-img'>
                <div>
                  <img alt='node js' src='/icon/nodejs.png' />
                  <p>Node JS</p>
                </div>
                <div>
                  <img alt='aws' src='/icon/aws.png' />
                  <p>AWS</p>
                </div>
                <div>
                  <img alt='Google Cloud Platform' src='/icon/gcp.png' />
                  <p>GCP</p>
                </div>
                <div>
                  <img alt='Naver Cloud Platform' src='/icon/ncloud.png' />
                  <p>Naver Cloud Platform</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='business-section2-wrapper'>
        <div className='business-section2-contentarea'>
          <div className='business-title-wrapper'>
            <div className='business-title-number'>02</div>
            <div className='business-title'>Maintenance</div>
          </div>
          <div className='business-content'>잘 설계된 지속가능한 CI/CD, Automation 시스템은 엄청난 리소스 감소로 이어집니다.</div>
          <div className='business-section2-container'>
            <div className='business-section2-card'>
              <div className='business-section2-card-text'>
                <div>CI / CD</div>
                <div>client-side, server-side application의 Testing, Deployment, 내부 업무시스템 모두 자동화로 업무 효율을 높여보세요.</div>
              </div>
              <div className='business-section2-card-img'>
                <div>
                  <img alt='circle ci' src='/icon/circleci.png' />
                  <p>circle ci</p>
                </div>
                <div>
                  <img alt='fastlane' src='/icon/fastlane.png' />
                  <p>fastlane</p>
                </div>
                <div>
                  <img alt='docker' src='/icon/docker.png' />
                  <p>docker</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='business-section3-wrapper'>
        <div className='business-section3-contentarea'>
          <div className='business-title-wrapper'>
            <div className='business-title-number'>03</div>
            <div className='business-title'>Data Analytical Design</div>
          </div>
          <div className='business-content'>갖고 계신 데이터들을 더 유용하게 활용하고 싶으신가요?<br />디에이티의 데이터 분석 솔루션을 활용해보세요.</div>
          <img alt='Data Visualization' src='/img/data-visualization.png' />
        </div>
      </div>
    </Container>
  );
};

export default Business;
