import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from 'pages/home';
import BlogLists from 'pages/blog/lists';
import BlogShow from 'pages/blog/show';
import RecruitLists from 'pages/recruit/lists';
import RecruitShow from 'pages/recruit/show';
import RecruitSubmit from 'pages/recruit/submit';
import Business from "pages/business";
import Contact from "pages/contact";
import Brand from "pages/brand";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/blog' element={<BlogLists />} />
        <Route path='/blog/:id' element={<BlogShow />} />
        <Route path='/recruit' element={<RecruitLists />} />
        <Route path='/recruit/show' element={<RecruitShow />} />
        <Route path='/recruit/submit' element={<RecruitSubmit />} />
        <Route path='/business' element={<Business />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/brand' element={<Brand />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
