import React from 'react';

import Container from 'components/container';

import 'stylesheets/recruit.scss';

const RecruitLists = () => {
  return (
    <Container paddingTop={0} backgroundColor='#111'>
      <div className='recruit-list-banner-wrapper'></div>
      <div className='recruit-list-ad-area'>
        <div className='recruit-list-ad-name'>채용공고</div>
        <div className='recruit-list-ad-wrapper'>
          <div className='recruit-list-ad'>
            <div className='recruit-list-ad-label-wrapper'>
              <div className='recruit-list-ad-label'>모집 중</div>
            </div>
            <div className='recruit-list-ad-title'>Senior Product Designer</div>
            <div className='recruit-list-ad-subtitle'>경력직 프로덕트 디자이너</div>
            <div className='recruit-list-ad-date'>~ 2022. 12. 31</div>
          </div>
          <div className='recruit-list-ad'>
            <div className='recruit-list-ad-label-wrapper'>
              <div className='recruit-list-ad-label'>상시 모집</div>
            </div>
            <div className='recruit-list-ad-title'>Senior Flutter Developer</div>
            <div className='recruit-list-ad-subtitle'>경력직 플러터 개발자</div>
            <div className='recruit-list-ad-date'>상시</div>
          </div>
          <div className='recruit-list-ad-end'>
            <div className='recruit-list-ad-label-wrapper'>
              <div className='recruit-list-ad-label'>마감</div>
            </div>
            <div className='recruit-list-ad-title'>Junior Product Designer</div>
            <div className='recruit-list-ad-subtitle'>신입 프로덕트 디자이너</div>
            <div className='recruit-list-ad-date'>~ 2022. 12. 31</div>
          </div>
          <div className='recruit-list-ad'>
            <div className='recruit-list-ad-label-wrapper'>
              <div className='recruit-list-ad-label'>모집 중</div>
            </div>
            <div className='recruit-list-ad-title'>Junior Web Developer</div>
            <div className='recruit-list-ad-subtitle'>신입 웹 개발자</div>
            <div className='recruit-list-ad-date'>~ 2022. 12. 31</div>
          </div>
        </div>
      </div>
      <div className='recruit-list-all-wrapper'>
        <div className='recruit-list-overview-area'>
          <div className='recruit-list-page-title'>인재상</div>
          <div className='recruit-list-title'>We Define ourselves inspirers</div>
          <div className='recruit-list-content'>"Inspirer is a person who makes someone feel<br />that they want to do something and can do it."<br /><br />우리가 정의한 Inspirer가 되기 위한, 4가지 Core Value를 소개합니다.</div>
        </div>
        <div className='recruit-list-section1-area'>
          <div className='recruit-list-section1-img'></div>
          <div className='recruit-list-section1-scriptarea'>
            <div className='recruit-list-section1-title'>
              <div>01</div>
              <div>Growth Mindset</div>
            </div>
            <div className='recruit-list-section1-script'><div>Growth Mindset은 시간, 성장 그래프의 기울기입니다. 이는 Infectious해서 조직의 성장으로 이어집니다.</div></div>
          </div>
        </div>
        <div className='recruit-list-section2-area'>
          <div className='recruit-list-section2-img'></div>
          <div className='recruit-list-section2-scriptarea'>
            <div className='recruit-list-section2-title'>
              <div>02</div>
              <div>Positive Mindset</div>
            </div>
            <div className='recruit-list-section2-script'><div>Positive Mindset은 새로운 도전과제에서 Inspiring Circle을 만들어, 문제해결의 새로운 방향을 제시합니다.</div></div>
          </div>
        </div>
        <div className='recruit-list-section3-area'>
          <div className='recruit-list-section3-img'></div>
          <div className='recruit-list-section3-scriptarea'>
            <div className='recruit-list-section3-title'>
              <div>03</div>
              <div>Initiative Mindset</div>
            </div>
            <div className='recruit-list-section3-script'><div>우리는 해결해야 할 문제를 절대 피하지 않습니다.<br />자기주도적으로 문제를 해결해 나갑니다.</div></div>
          </div>
        </div>
        <div className='recruit-list-section4-area'>
          <div className='recruit-list-section4-img'></div>
          <div className='recruit-list-section4-scriptarea'>
            <div className='recruit-list-section4-title'>
              <div>04</div>
              <div>Reasonable Communication</div>
            </div>
            <div className='recruit-list-section4-script'><div>우리는 동료와 의견이 충돌하는 것을 두려워 하지 않습니다.<br />근거를 기반으로 한 합리적인 의사소통을 통해서 더 나은 결과물을 만듭니다.</div></div>
          </div>
        </div>

      </div>
    </Container>
  );
};

export default RecruitLists;
