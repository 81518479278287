import React from 'react';

import Container from 'components/container';

import 'stylesheets/recruit.scss';

const RecruitShow = () => {
  return (
    <Container paddingTop={0} backgroundColor='#111'>
      <div className='recruit-show-wrapper'>
        <div className='recruit-show-area'>
          <div className='recruit-show-head'>
            <div className='recruit-show-label'>모집 중</div>
            <div className='recruit-show-title'>Senior Product Designer</div>
            <div className='recruit-show-subtitle'>경력직 프로덕트 디자이너 채용</div>
            <div className='recruit-show-date'>2022. 11. 1 ~ 2022. 12. 31</div>
          </div>
          <div className='recruit-show-body'>
            보배를 같이, 뭇 전인 그들은 가는 어디 부패뿐이다.<br/>
            능히 우리 거친 예가 목숨을 것이다.<br/>
            얼마나 보내는 우리의 몸이 든 피부가 찬미를 같으며, 아니한 아름다우냐?<br/>
            <br/>
            생의 넣는 창공에 얼마나 따뜻한 교향악이다.<br/>
            - Sketch / Figma / Adobe XD<br/>
            - Wireframe<br/>
            - Visual Branding<br/>
            <br/>
            피에 피가 앞이 그들에게 어디 뛰노는 것이다.<br/>
            보라, 피다. 끓는 위하여 생생하며, 오아이스도 인간에 품에 피어나는 유소년에게서 그와 그리하였는가?<br/>
            만천하의 꽃 굳세게 황금시대다.<br/>
            봄날의 구하기 이것은 사막이다.<br/>
            든 돋고, 커다란 이상을 무엇을 속에서 살았으며, 것이다. 공자는 오직 석가는 것이다.<br/>
            <br/>
            만천하의 소금이라 따뜻한 황금시대의 가슴에 목숨이 불어 때문이다. 현저하게 있음으로써 품었기 그들의 꽃이 청춘은 실현에 역사를 공자는 뿐이다. 간에 청춘 예수는 할지라도 교향악이다. 인간은 피는 이상의 것이 이성은 목숨을 넣는 쓸쓸하랴? 인생의 방황하여도, 품으며, 이것이다.설레는 살 이 하였으며, 그들은 때문이다. 얼마나 품으며, 기관과 끝에 타오르고 끓는 쓸쓸하랴? 오아이스도 그들의 보배를 품고 같은 가지에 것이다. 놀이 것이 피부가 영락과 인생을 칼이다.풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다.<br/>
            <br/>
            긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐? 무엇이 품에 튼튼하며, 위하여, 구할 사는가 발휘하기 생명을 행복스럽고 것이다. 위하여 가치를 인생을 때문이다. 인생을 같지 무한한 곳으로 찾아다녀도, 같은 인류의 긴지라 황금시대의 칼이다. 길을 오직 그들의 위하여, 청춘의 간에 고동을 부패뿐이다. 얼마나 못할 우는 이 아니다. 미인을 싹이 이상 피가 행복스럽고 철환하였는가? 하였으며, 발휘하기 크고 어디 그들은 관현악이며, 보이는 이것이다.<br/>
          </div>
          <div className='recruit-show-btn-wrapper'>
            <button>지원하기</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RecruitShow;
