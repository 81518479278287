import React, {useState, useEffect, useRef, useCallback} from 'react';
import {throttle} from 'throttle-debounce';

import 'stylesheets/scroll.scss';

const Scroll = () => {
  const [, updateState] = useState(0);
  const forceUpdate = useCallback(() => updateState((v) => v + 1), []);

  const [scrollBarHeight, setScrollBarHeight] = useState(0);
  const scrollbarHeightRef = useRef();
  const positionRef = useRef();

  const handleScroll = throttle(100, () => {
    const enableScrollBarHeight = window.innerHeight - scrollbarHeightRef.current;
    const enableScrollHeight = window.document.body.offsetHeight - window.innerHeight;

    const scrollRatio = (window.scrollY / enableScrollHeight);

    positionRef.current = enableScrollBarHeight * scrollRatio;
    forceUpdate();
  });

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const offsetHeight = window.document.body.offsetHeight;
    scrollbarHeightRef.current = (innerHeight * innerHeight) / offsetHeight;
    setScrollBarHeight(scrollbarHeightRef.current);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='scroll-container'>
      <div
        className='scroll-div'
        style={{height: scrollBarHeight, top: `${positionRef.current}px`}}
      />
    </div>
  );
};

export default Scroll;
