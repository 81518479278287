import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import 'stylesheets/nav.scss';

const getNavItemClass = (currentPath, targetPath) => {
  return currentPath === targetPath
    ? 'nav-item-active'
    : 'nav-item';
};

const Nav = ({ isScrollTop }) => {
  const location = useLocation();
  const pathname = location.pathname;

  const openMenuDrawer = () => {
    document.getElementById('menu-drawer').style.maxWidth = '100vw';
  };

  return (
    <div className='nav-wrapper' style={{ backgroundColor: isScrollTop ? 'rgba(0, 0, 0, 0)' : '#111' }}>
      <div className='nav-container'>
        <Link to='/'>
          <img className='nav-logo' src="/logo-white.png" alt="logo" />
        </Link>
        <div className='nav-row'>
          <Link to='/brand'>
            <div className={getNavItemClass(pathname, '/brand')}>Brand</div>
          </Link>
          <Link to='/business'>
            <div className={getNavItemClass(pathname, '/business')}>Business</div>
          </Link>
          <Link to='/recruit'>
            <div className={getNavItemClass(pathname, '/recruit')}>Recruit</div>
          </Link>
          <Link to='/blog'>
            <div className={getNavItemClass(pathname, '/blog')}>Blog</div>
          </Link>
          <div className='nav-item-divider' />
          <Link to='/contact'>
            <div className='nav-item-button'>Contact</div>
          </Link>
        </div>
        <img
          alt="nav-menu"
          src="/icon/nav-menu.png"
          className='nav-mobile-menu-button'
          onClick={openMenuDrawer}
        />
      </div>
    </div>
  );
};

export default Nav;
