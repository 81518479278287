import React from 'react';

import 'stylesheets/footer.scss';

const Footer = () => {
  return (
    <div className='footer-wrapper'>
      <div className='footer-container'>
        <div className='footer-row-1'>
          <div className='text-1'>주식회사 디에이티</div>
          <div className='divider-1' />
          <div className='footer-item-row'>
            <div className='text-1'>사업자등록번호</div>
            <div className='text-2'>690-81-02718</div>
          </div>
          <div className='divider-1' />
          <div className='footer-item-row'>
            <div className='text-1'>대표이사</div>
            <div className='text-2'>차영광</div>
          </div>
          <div className='divider-1' />
          <div className='footer-item-row'>
            <div className='text-1'>이메일</div>
            <div className='text-2'>contact@d-eighty.com</div>
          </div>
          <div className='divider-1' />
          <div className='footer-item-row'>
            <div className='text-1'>주소</div>
            <div className='text-2'>대구광역시 북구 동북로 223, 3층</div>
          </div>
        </div>
        <div className='footer-copyright'>Copyright © D80 Inc. All Rights Reserved.</div>
      </div>
    </div>
  );
};

export default Footer;
