import React from 'react';

import Container from 'components/container';

import 'stylesheets/recruit.scss';

const RecruitSubmit = () => {
  return (
    <Container paddingTop={0} backgroundColor='#111'>
        <div className='recruit-submit-wrapper'>
            <div className='recruit-submit-area'>
                <div className='recruit-submit-head'>
                    <div className='recruit-submit-title'>지원하기</div>
                    <div className='recruit-submit-info'>
                        <div className='recruit-submit-info-title'>Senior Product Designer</div>
                        <div className='recruit-submit-info-subtitle'>경력직 프로덕트 디자이너 채용</div>
                        <div className='recruit-submit-info-date'>2022. 11. 1 ~ 2022. 12. 31</div>
                    </div>
                </div>
                <div className='recruit-submit-body'>
                    <div className='recruit-submit-notice'>※ 모든 항목은 필수 입력값입니다.</div>
                    <div className='recruit-submit-name'>
                        <div className='recruit-submit-name-label'>이름</div>
                        <div className='recruit-submit-name-wrapper'>
                            <input type='text' placeholder='ex) 홍길동' />
                        </div>
                    </div>
                    <div className='recruit-submit-mobile'>
                        <div className='recruit-submit-mobile-label'>휴대전화 번호</div>
                        <div className='recruit-submit-mobile-wrapper'>
                            <input type='tel' placeholder='ex) 01012341234' /></div>
                    </div>
                    <div className='recruit-submit-resume'>
                        <div className='recruit-submit-resume-label'>이력서</div>
                        <div className='recruit-submit-resume-wrapper'>
                            <button><img src='/icon/upload.png' />파일 업로드</button>
                        </div>
                    </div>
                    <div className='recruit-submit-portfolio'>
                        <div className='recruit-submit-portfolio-label'>포트폴리오</div>
                        <div className='recruit-submit-portfolio-wrapper'>
                            <div className='recruit-submit-portfolio-input'>
                                <div className='recruit-submit-portfolio-radio-selectarea'>
                                    <input type='radio' name='portfolio' id='portfolio-URL' />
                                    <label for='portfolio-URL'><div className='fake-radio'><div></div></div>URL</label>
                                </div>
                                <div className='recruit-submit-portfolio-radio-selectarea'>
                                    <input type='radio' name='portfolio' id='portfolio-file' />
                                    <label for='portfolio-file'><div className='fake-radio'><div></div></div>파일첨부</label>
                                </div>
                            </div>
                            <button><img src='/icon/upload.png' />파일 업로드</button>
                        </div>
                    </div>
                    <div className='recruit-submit-introduce'>
                        <div className='recruit-submit-introduce-label'>자기소개서</div>
                        <div className='recruit-submit-introduce-wrapper'>
                            <div className='recruit-submit-introduce-subject'>
                                <div className='recruit-submit-introduce-subject-text'>01 보배를 같이, 뭇 전인 그들은 가는 어디 부패뿐이다. 능히 우리 거친 예가 목숨을 것이다.</div>
                            </div>
                            <div className='recruit-submit-introduce-textarea-wrapper'>
                                <textarea className='recruit-submit-introduce-textarea' placeholder='내용을 입력해 주세요.' />
                                <div className='recruit-submit-introduce-length'>0/1000</div>
                            </div>
                            <div className='recruit-submit-introduce-subject'>
                                <div className='recruit-submit-introduce-subject-text'>02 보배를 같이, 뭇 전인 그들은 가는 어디 부패뿐이다. 능히 우리 거친 예가 목숨을 것이다.</div>
                            </div>
                            <div className='recruit-submit-introduce-textarea-wrapper'>
                                <textarea className='recruit-submit-introduce-textarea' placeholder='내용을 입력해 주세요.' />
                                <div className='recruit-submit-introduce-length'>0/1000</div>
                            </div>
                            <div className='recruit-submit-introduce-subject'>
                                <div className='recruit-submit-introduce-subject-text'>03 보배를 같이, 뭇 전인 그들은 가는 어디 부패뿐이다. 능히 우리 거친 예가 목숨을 것이다.</div>
                            </div>
                            <div className='recruit-submit-introduce-textarea-wrapper'>
                                <textarea className='recruit-submit-introduce-textarea' placeholder='내용을 입력해 주세요.' />
                                <div className='recruit-submit-introduce-length'>0/1000</div>
                            </div>
                            <div className='recruit-submit-introduce-subject'>
                                <div className='recruit-submit-introduce-subject-text'>04 보배를 같이, 뭇 전인 그들은 가는 어디 부패뿐이다. 능히 우리 거친 예가 목숨을 것이다.</div>
                            </div>
                            <div className='recruit-submit-introduce-textarea-wrapper'>
                                <textarea className='recruit-submit-introduce-textarea' placeholder='내용을 입력해 주세요.' />
                                <div className='recruit-submit-introduce-length'>0/1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='recruit-submit-btn-wrapper'>
                    <button>지원하기</button>
                </div>
            </div>
        </div>
    </Container>
  );
};

export default RecruitSubmit;
