import React, {useEffect, useState} from 'react';

import {throttle} from 'throttle-debounce';

import Nav from 'components/nav';
import Footer from 'components/footer';
import MobileMenuDrawer from 'components/mobile_menu_drawer';
import Scroll from 'components/scroll';

import 'stylesheets/container.scss';

const Container = ({
  children,
  paddingTop = 65,
  backgroundColor = '#000',
}) => {
  const [isScrollTop, setIsScrollTop] = useState(true);

  const handleScroll = throttle(400, () => {
    setIsScrollTop(window.scrollY === 0);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='container-wrapper' style={{backgroundColor}}>
      <MobileMenuDrawer />
      <div className='container-nav-wrapper'>
        <Nav isScrollTop={isScrollTop} />
      </div>
      <div className='container-scroll-wrapper'>
        <Scroll />
      </div>
      <div
        className='container-children-wrapper'
        style={{'paddingTop': `${paddingTop}px`}}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Container;
