export const CLIENT_TYPE = Object.freeze({
  'COM': 'com',
  'LAB': 'lab',
  'GOV': 'gov'
});

export const clientName = {
  [CLIENT_TYPE.COM]: '일반 기업',
  [CLIENT_TYPE.LAB]: '대학 연구실',
  [CLIENT_TYPE.GOV]: '공공기관·정부부처·지자체',
};

export const clientDetail = {
  [CLIENT_TYPE.COM]: `고객사의 전문성과 IT 기술을 융합하여,${'\n'}새로운 가치를 창출하는 생산적 경험을 디에이티는 언제나 환영합니다.`,
  [CLIENT_TYPE.LAB]: `여러 분야의 대학 연구실과 협업하여 새로운 기술을 개발합니다.${'\n'}R&D 협업을 언제나 환영합니다.`,
  [CLIENT_TYPE.GOV]: `지자체, 정부부처와 함께 다양한 플랫폼을 개발하고 있습니다.${'\n'}신규사업 역시 언제나 환영합니다.`,
};

export const clientImage = {
  [CLIENT_TYPE.COM]: '/img/consulting-company.png',
  [CLIENT_TYPE.LAB]: '/img/consulting-lab.png',
  [CLIENT_TYPE.GOV]: '/img/consulting-government.png',
};