import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { CLIENT_TYPE, clientName, clientDetail, clientImage } from 'configs/client';
import getQuery from 'utils/get_guery';

import Container from 'components/container';

import 'stylesheets/home.scss';
import 'stylesheets/business.scss';

const Home = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [tab, setTab] = useState(CLIENT_TYPE.COM);

  useEffect(() => {
    const query = getQuery(search);
    if (query['tab'] === CLIENT_TYPE.LAB) setTab(CLIENT_TYPE.LAB);
    else if (query['tab'] === CLIENT_TYPE.GOV) setTab(CLIENT_TYPE.GOV);
    else setTab(CLIENT_TYPE.COM);
  }, [search]);

  const showBlog = () => {
    alert('미공개 글입니다');
  };

  const moveConsultingTab = (type) => () => {
    navigate(`?tab=${type}`, { replace: true });
  };

  const getConsultingTabStyle = (type) => {
    let classes = 'business-consulting-tab-menu';

    if (type === tab) classes += ' consulting-tab-active';

    return classes;
  };

  const getTabTitle = () => clientName[tab];

  const getTabContent = () => clientDetail[tab];

  const getTabImage = () => clientImage[tab];


  return (
    <Container paddingTop={0}>
      <div className='main-banner-wrapper'>
        <div className='main-banner-container'>
          <div className='main-banner-title'>고객의 비즈니스에 최적화된<br />High-End Solution 개발</div>
          <div className='main-banner-content'>디에이티는 설계단계부터 면밀히 분석하여,{'\n'}고객의 상황에 최적화된 IT 솔루션을 개발합니다</div>
          <Link to='/business'>
            <div className='main-link-button'>
              비즈니스 바로가기
              <img alt="arrow-right" src="/icon/arrow-right-white-16.png" />
            </div>
          </Link>
        </div>
      </div>
      <div className='main-consulting-wrapper'>
        <div className='main-consulting-container'>
          <div className='main-consulting-label'>Consulting</div>
          <div className='business-consulting-tab-wrapper'>
            <div className='business-consulting-tab-menu-wrapper'>
              <div className={getConsultingTabStyle(CLIENT_TYPE.COM)} onClick={moveConsultingTab(CLIENT_TYPE.COM)}><img alt="company" src='/icon/consulting-tab-1.png' />일반 기업</div>
              <div className={getConsultingTabStyle(CLIENT_TYPE.LAB)} onClick={moveConsultingTab(CLIENT_TYPE.LAB)}><img alt="lab" src='/icon/consulting-tab-2.png' />대학 연구실</div>
              <div className={getConsultingTabStyle(CLIENT_TYPE.GOV)} onClick={moveConsultingTab(CLIENT_TYPE.GOV)}><img alt="government" src='/icon/consulting-tab-3.png' />공공기관·정부부처·지자체</div>
            </div>
            <div className='business-consulting-tab-content-wrapper'>
              <div className='business-consulting-img' style={{ backgroundImage: `url(${getTabImage()})` }}></div>
              <div className='business-consulting-text-wrapper'>
                <div className='business-consulting-title'>{getTabTitle()}</div>
                <div className='business-consulting-content'>{getTabContent()}</div>
                <Link to="/contact">
                  <div className='business-consulting-btn'>문의하기<img alt="arrow-right" src="/icon/arrow-right-black-16.png" /></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='main-brand-section'>
        <div className='main-brand-container'>
          <img alt="brand" src="/img/main-brand.jpg" className='main-brand-img' />
          <div className='main-brand-content-section'>
            <div className='main-brand-title'>Draw Inspiring Circles</div>
            <div className='main-brand-content'>디에이티는 사람들이 서로 Inspiration을 주고 받는 것을 Inspiring Circle로 정의하고, 이러한 선순환을 지속적으로 만들고자 합니다.</div>
            <Link to='/brand'>
              <div className='main-link-button'>
                브랜드 바로가기
                <img alt="arrow-right" src="/icon/arrow-right-white-16.png" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='main-dbasin-section'>
        <div className='main-dbasin-container'>
          <img alt="dbasin" src="/img/main-dbasin-logo.png" className='main-dbasin-logo' />
          <div className='main-dbasin-comming-soon'>Comming Soon (22년 하반기)</div>
          <div className='main-dbasin-intro'>Inspiring Circles을 만들기 위해,<br />지역 청년들과 함께 소프트웨어 교육 브랜드를 런칭합니다.</div>
        </div>
      </div>
      <div className='main-blog-section'>
        <div className='main-blog-container'>
          <div className='main-blog-header'>
            <div className='main-blog-label'>Blog</div>
            <Link to='/blog'>
              <div className='main-link-button'>
                블로그 바로가기
                <img alt="arrow-right" src="/icon/arrow-right-white-16.png" />
              </div>
            </Link>
          </div>
          <div className='main-blog-row'>
            {/* <div className='main-blog-item' onClick={showBlog}>
              <div className='main-blog-thumbnail' style={{backgroundImage: 'url(\'/dummy/blog-01.png\')'}}></div>
              <div className='main-blog-title'>Flutter 기반 Call App 제작</div>
              <div className='main-blog-content'>3가지 OS(Android, iOS, windows)에서 클라이언트 프로그램이 필요했고, 추후 MacOS 확장도 고려해야했기 때문에...</div>
              <div className='main-blog-author'>Dev Lead 차영광 · 2022.04.01</div>
            </div> */}
            <div className='main-blog-item' onClick={showBlog}>
              <div className='main-blog-thumbnail' style={{ backgroundImage: 'url(\'/dummy/blog-02.png\')' }}></div>
              <div className='main-blog-title'>디에이티 브랜딩 일지</div>
              <div className='main-blog-content'>회사의 리브랜딩을 작업을 시작하기 전에 가장 먼저 고민한건, "왜 리브랜딩이 필요한가?"에 대한 물음이였다. 비즈니스에서...</div>
              <div className='main-blog-author'>Design Lead 안상근 · 2022.04.01</div>
            </div>
            <div className='main-blog-item' onClick={showBlog}>
              <div className='main-blog-thumbnail' style={{ backgroundImage: 'url(\'/dummy/blog-03.png\')' }}></div>
              <div className='main-blog-title'>Circle CI 기반 CI/CD 템플릿 개발</div>
              <div className='main-blog-content'>여러 서비스를 유지보수 하고, 각 서비스마다 개발 및 서비스 환경이 다르다 보니, 점점 서비스를 유지보수하는데 반복된...</div>
              <div className='main-blog-author'>Dev Lead 정승덕{'  '}·{'  '}2022.04.01</div>
            </div>
          </div>
        </div>
      </div>
      <div className='main-recruit-section'>
        <div className='main-recruit-label'>Recruit</div>
        <div className='main-recruit-title'>We Define ourselves inspirers</div>
        <div className='main-recruit-content'>
          "Inspirer is a person who makes someone feel<br />that they want to do something and can do it."
        </div>
        <Link to='/contact'>
          <div className='main-link-button'>
            채용 바로가기
            <img alt="arrow-right" src="/icon/arrow-right-white-16.png" />
          </div>
        </Link>
      </div>
      <div className='main-contact-section'>
        <div className='main-contact-title'>문의사항이 있으신가요?</div>
        <div className='main-contact-content'>사업제안 및 제휴등 자유롭게 문의해주세요.</div>
        <Link to='/contact'>
          <div className='main-link-button'>
            컨택트 바로가기
            <img alt="arrow-right" src="/icon/arrow-right-white-16.png" />
          </div>
        </Link>
      </div>
    </Container>
  );
};

export default Home;
