import React from 'react';
import ReactMarkdown from 'react-markdown';

import Container from 'components/container';

const MarkDown = () => {
  const children = `# 우리는 왜 Circle CI를 사용하는가?

  디에이티 프로젝트의 빌드와 배포 자동화를 위한 CICD 시스템을 만들기 위해 고민하였고,
  여러 레포지토리에 있는 소스들의 관리를 위해 기존 Github Action에서  Circle CI로 마이그레이션하기로 결정하였습니다.
  이 글에서는 어떻게 Circle CI를 선정하게 되었는지 설명하겠습니다.    

    

  > Q: "승덕님 CICD가 무엇인가요?"
  A: "소프트웨엔지니어링에서 CI는 지속적인 통합 CD는 지속적인 배포를 뜻합니다.
  CICD 시스템의 궁극적인 목표는 애플리케이션 빌드와 배포, 테스트의 자동화를 통해 개발 및 운영의 안정화와 팀 간의 격차를 줄일 수 있다고 생각합니다."
    
  
    
  ## 마이그레이션의 필요성
  ---
  기존의 CICD의 경우 github라는 플랫폼 안에서 사용할 수 있는 github action를 활용하여 빌드, 배포, 테스트를 자동화하여 사용하고 있었다.
  하나의 플랫폼 안에서 소스코드와 프로덕트를 관리할 수 있다는 측면에서 굉장히 이점이 있다고 생각했다.
  
  관리하는 프로젝트가 많아지면서, 모든 프로젝트의 현재 진행상황을 공유하기가 힘들어졌고 이는 개발자들의 소통 문제로 이어졌다.
  
  이와 같은 상황에서 개발 효율성을 올리고자 필요하다면 마이그레이션을 진행하기로 하고 내부 세션 때 올라왔던 Circle CI와 비교해보기로 했다.
  
  ## Github Action vs Circle CI
  ---
  #### 1차 테스트
  다음과 같은 평가 기준으로 검토한 후에 각 부분에 대해서 적절한 의견을 공유했다.
  
  
  - Speed(속도) : 빌드 속도가 상대적으로 느린가?
  - Readability(가독성) : UI의 가독성이 좋은가?
  - Learning Curve(학습 비용) : 초심자가 쉽게 학습할 수 있는가? 
  - Community size(커뮤니티 활성도) : 이슈가 발생했을 때, 커뮤니티에서 답을 찾을 수 있는가?
  - Stability(안정성) : 서비스 크래시가 발생하는가?
  - Compatibility(호환성) : github와 같이 쓸 수 있는가?
  
  ![table](https://firebasestorage.googleapis.com/v0/b/decaffeine-a86d4.appspot.com/o/table-3.png?alt=media&token=4a5a159d-2aeb-4561-9cc8-99db11cb71c6)
  
  1차 평가 결과 속도와 가독성 측면에서 Circle CI가 좋은 평가를 받았고,
  커뮤니티와 학습 비용 부분은 풀어야할 숙제가 되었다.
  
  가독성 부분은 가장 큰 결정 요인 중 하나가 되었고, 통합 대시보드를 지원해주는 것이 흩어져 있는 레포지토리를 관리하는데 용이할 것으로 예상이 된다.
  ![table](https://firebasestorage.googleapis.com/v0/b/decaffeine-a86d4.appspot.com/o/table-4.png?alt=media&token=62359341-ddb6-4fdd-8eb5-ef73723dbff8)
  (출처: [CircleCI](https://app.circleci.com/dashboard))
  
  
  걱정했던 부분인 마이그레이션 부분에 대한 템플릿 부분과 함께 github action에서의 마이그레이션 방법에 대해 공식문서에서 제공해주고 있었다.
  ![table](https://firebasestorage.googleapis.com/v0/b/decaffeine-a86d4.appspot.com/o/table-2.png?alt=media&token=e907cf55-c6f0-4e9f-9d0e-4b66a9612ff3)
  

  #### 2차 테스트
  2차 테스트에서는 속도 테스트를 진행하였다.
  공식문서에서는으로 CircleCI에서 40%이상 단축할 수 있다고 한다.
  
  ![table](https://firebasestorage.googleapis.com/v0/b/decaffeine-a86d4.appspot.com/o/table-5.png?alt=media&token=0f28507a-d238-4a86-bb9e-ce5e52516523)
  (출처: [CircleCI](https://circleci.com/circleci-versus-github-actions/?utm_source=google&utm_medium=sem&utm_campaign=sem-google-dg--japac-en-githubActions-tImpShare-auth-nb&utm_term=g_p-github%20actions_c__powerful_20210903&utm_content=sem-google-dg--japac-en-githubActions-tImpShare-auth-nb_keyword-text_eta-githubActions_mixed-&gclid=Cj0KCQjwspKUBhCvARIsAB2IYusGlf50Tt1Xd6UTA-C1c96TWzwxFJrWL898JgpfkwuLmMzVEpfjw0IaAiHXEALw_wcB))
  
  
  디에이티 프로젝트의 github action 빌드 시간과 circle ci로 마이그레이션 했을 때의 시간을 비교해보았다.
  
  ![table](https://firebasestorage.googleapis.com/v0/b/decaffeine-a86d4.appspot.com/o/table-1.png?alt=media&token=d7e6d9fd-ad2a-46c3-9704-671fbee55e70)
  
  개발 환경에 따라 차이가 존재하겠지만, 결과적으로 시간이 단축된 부분이 만족스러웠다.   
  
  
  ## 마이그레이션을 마치며
  ---
  한 플랫폼 안에서 해결하는 것보다 여러 서비스를 잘 이용하는 것이 개발 효율을 늘릴 수 있다는 결론을 얻게 되었고
  
  실제로 샘플 코드를 구현하며 기존 github action과 비교를 마쳐 가독성과 속도를 생각한다면, 학습 비용은 감수할 수 있는 수준이다. 
  전체 프로젝트를 마이그레이션 후 신규 프로젝트는 모두 Circle CI로 진행될 것 같다.
  
  Github Action은 레퍼지토리 각각을 소스관리와 함께 배포, 빌드, 테스트 하기 용이한 서비스인 것 같고, 
  Circle CI는 여러 레퍼지토리에 걸쳐있는 프로젝트에 적합한 것 같다.
  프로젝트의 상황에 맞춰 적절하게 활용해보자.`;

  return (
    <div className='blog-show-md-wrapper'>
      <ReactMarkdown children={children} />
    </div>
  );
}

const BlogShow = () => {
  return (
    <Container paddingTop={0} backgroundColor='#171717'>
      <div className='blog-show-banner-wrapper' style={{background: `url("/img/sample-blog-banner.png") no-repeat center center/cover`}}>
        <div></div>
      </div>
      <div className='blog-show-post-wrapper'>
        <div className='blog-show-post-area'>
          <div className='blog-show-post-head'>
            <div className='blog-show-post-title'>우리는 왜 Circle CI를 사용하는가?</div>
            <div className='blog-show-post-subtext'>
              <div>Dev Lead 정승덕</div>
              <div>·</div>
              <div>2022.05.19</div>
            </div>
          </div>
          <div className='blog-show-post-body'>
            <MarkDown />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BlogShow;
