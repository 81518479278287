import React from 'react';

import Container from 'components/container';

import 'stylesheets/blog.scss';

const BlogLists = () => {
  return (
    <Container paddingTop={0} backgroundColor='#171717'>
      <div className='blog-list-banner-wrapper'></div>
      <div className='blog-list-tab'>
        <div className='blog-list-tab-menu-selected'>전체</div>
        <div className='blog-list-tab-menu'>기획·디자인</div>
        <div className='blog-list-tab-menu'>개발</div>
      </div>
      <div className='blog-list-wrapper'>
        <div className='blog-list-area'>
          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample1.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample2.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample3.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample4.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.포스트의 제목 입니다.포스트의 제목 입니다.포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample5.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample6.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample1.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample2.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample3.png") no-repeat center center/cover`}}></div>
            </div>
          </div>

          <div className='blog-list-post'>
            <div className='blog-list-post-textarea'>
              <div className="blog-list-post-title">포스트의 제목 입니다.</div>
              <div className="blog-list-post-script">풍부하게 없으면, 같이, 것은 가는 얼마나 역사를 봄날의 있다. 꾸며 위하여 산야에 사라지지 싹이 사막이다. 긴지라 구하지 속에서 끓는 무한한 보는 아름다우냐?</div>
              <div className="blog-list-post-subtext">
                <div>작성자</div>
                <div>·</div>
                <div>2022.04.26</div>
              </div>
            </div>
            <div className='blog-list-post-img-wrapper'>
              <div className='blog-list-post-img' style={{background: `url("/img/sample4.png") no-repeat center center/cover`}}></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BlogLists;
